<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none mr-1"
        :class="{'hide-in-event': $route.name == 'apps-bookings-futurebookings-details'}"
    >
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto nav-right_side">
      <locale/>
      <dark-Toggler class="d-none d-lg-block"/>
      <search-bar/>
      <notification-dropdown/>
   <CalendarIcon/>
      <Credits/>
      <user-dropdown/>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import Credits from "@core/layouts/components/app-navbar/components/Credits";
import CalendarIcon from './components/CalendarIcon.vue'



export default {
  components: {
    CalendarIcon,
    Credits,
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
}
</script>
<style>
@media screen and (max-width: 560px) {
  .nav-right_side {
    width: 100%;
    justify-content: space-between;
  }
}
.hide-in-event{
  display: none;
}


</style>