<template>
  <b-badge pill :variant="badgeColor" class="mx-md-1" >
   <span class="currency_symbol"></span>
    {{ credits }} {{ $t("Credits") }}
  </b-badge>
</template>

<script>
import {BBadge,} from 'bootstrap-vue'
import {mapGetters} from "vuex";

export default {
  name: "Credits",
  components: {
    BBadge
  },
  data() {
    return {
      credits: 0
    }
  },
  mounted() {
    this.getCredits()
    setInterval(()=>
      this.getCredits()
    ,600000)

  },
  methods: {
    getCredits() {
      this.$http.get('/info/credits').then((response) => {
        this.credits = response.data.credit_amount
      })
    }
  },
  computed: {
    badgeColor() {
      if (this.credits < 200) return 'danger'
      if (this.credits >= 200 && this.credits < 500) return 'warning'
      if (this.credits >= 500) return 'success'
      return 'primary'
    }
  }
}
</script>
