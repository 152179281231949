import { ref, watch } from '@vue/composition-api'
import axios from 'axios'

export default function useAutoSuggest(props) {
  const filteredData = ref({})
  let searchFlag = false;
  let old_search =""
  /**
   * Filter group against provided query
   * Grp Structure:
   * {
   *    key: 'title',
   *    data: [
   *      title: 'Admin', img: 'someImage.png',
   *      title: 'Template', img: 'otherImage.png',
   *    ]
   * }
   * @param {Object} grp Group object to perform filter on
   * @param {String} query Query string to filter
   */
  const filterGrp = (grp, query) => {
    const exactEle = grp.data.filter(item => item[grp.key].toLowerCase().startsWith(query.toLowerCase()))
    const containEle = grp.data.filter(
      // prettier-ignore
      item => !item[grp.key].toLowerCase().startsWith(query.toLowerCase()) && item[grp.key].toLowerCase().indexOf(query.toLowerCase()) > -1,
    )
    return exactEle.concat(containEle).slice(0, props.searchLimit)
  }

  const searchQuery = ref('')
  const resetsearchQuery = () => {
    searchQuery.value = ''
  }

  const handleSearchQueryUpdate = val => {
    if (val === '') {
      filteredData.value = {}
    } else {
      const queriedData = {}
      const dataGrps = Object.keys(props.data)

      dataGrps.forEach((grp, i) => {
        queriedData[dataGrps[i]] = filterGrp(props.data[grp], val)
      })

      filteredData.value = queriedData
    }
  }
  const searchData = searchQuery => {
    let token = localStorage.getItem("accessToken");
    let url = process.env.VUE_APP_ROOT_API + 'expert/search'
    axios.get(url, {
      params: { query: searchQuery },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      }
    })
      .then(response => {
        props = response
        searchFlag = true
        old_search = searchQuery
        handleSearchQueryUpdate(searchQuery)
      })
      .catch(error => {
        // this.loaded = false
        // if (error.response.status === 404) { }
      })
  }

  // watch(searchQuery, val => handleSearchQueryUpdate(val))
  watch(searchQuery, val => {
    console.log(searchFlag);
    if (val != old_search && val.length >= 3){
        setTimeout(() => {
          searchData(val)
        }, 1000);
    }
    
    
  })

  return {
    searchQuery,
    resetsearchQuery,
    filteredData,
  }
}
