<template>
  <div v-if="getUncompletedProcesses().length > 0">
    <b-overlay :show="!loaded" rounded="sm">
    <b-card :title="$t('Optimize your expert profile') + ' (' + (loaded ? (100-getUncompletedProcesses().length/USER_ONBOARDING.length*100) : '0') + '% ' + $t('completed') + ')'">
      <b-progress :max="100" height="1.5rem">
        <b-progress-bar
            v-for="(item) in USER_ONBOARDING"
            :key="item.id"
            :variant="(item.done ? 'success' : 'danger')"
            :value="25"
            :label="''"
            show-progress
            class="align-items-center"
            @mouseover.native="item.hover = true"
            @mouseleave.native="item.hover = false"
            v-b-tooltip.hover.top="$t(getProcessItem(item.id).name)"
        >
          <feather-icon
              size="14"
              :icon="item.done ? 'CheckCircleIcon' : 'XCircleIcon'"
              class="award"
          />
        </b-progress-bar>
      </b-progress>
      <div class="mt-3">
        <h5>{{ $t('Open tasks') }}</h5>
      </div>
      <b-form-row>
        <b-col md="3" class="mb-2 mb-md-0" v-for="(item, index) in USER_ONBOARDING" :key="item.id">
          <div class="border p-1 h-100 d-flex flex-column onboarding-item" :class="(item.hover ? 'bg-light' : '')">
            <strong class="d-flex align-content-center align-items-center">
              <feather-icon
                  size="14"
                  icon="CheckCircleIcon"
                  v-if="item.done"
                  class="mr-1 text-success"
              />
              {{ $t(getProcessItem(item.id).name) }}
            </strong>
            <p class="">
              {{ $t(getProcessItem(item.id).description) }}
            </p>
            <b-button
                class="mt-auto"
                size="sm"
                variant="success"
                @click="update"
                :to="getProcessItem(item.id).route"
                v-if="!item.done"
            >
              {{ $t(getProcessItem(item.id).action) }}
            </b-button>
          </div>
        </b-col>
      </b-form-row>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge, BButton, BCard,
  BCol,
  BFormGroup,
  BFormInput, BFormRow,
  BFormSelect,
  BInputGroup, BInputGroupAppend, BOverlay,
  BPagination, BProgress, BProgressBar,
  BRow,
  BTable, VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'OnBoardingProcess',
  components: {
    BTable,
    BBadge,
    BRow,
    BFormRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BProgressBar,
    BProgress,
    BOverlay,
    VBTooltip,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loaded: false,
      processItem: {},
      processData: [
        {
          id: 'profile-image',
          name: ('Profile image'),
          description: ('Add a profile image'),
          done: false,
          hover: true,
          action:  ('Upload a profile image'),
          route: { name: 'experts-profile', params: {activeTab: 0} }
        },
        {
          id: 'profile-data',
          name: ('Complete your profile'),
          description: ('Complete your personal profile data'),
          done: false,
          hover: true,
          action:  ('Complete your profile'),
          route: { name: 'experts-profile', params: {activeTab: 1} }
        },
        {
          id: 'timemanagement',
          name: ('Time management'),
          description: ('Define your times in which you can be booked by customers.'),
          done: false,
          hover: false,
          action:  ('Manage your time'),
          route: { name: 'apps-bookingtimes-new' }
        },
        {
          id: 'first-event',
          name: ('First event'),
          description: ('Create your first event'),
          done: false,
          hover: false,
          action:  ('Create your first event'),
          route: { name: 'apps-events-new' }
        },
      ]
    }
  },
  props: {
  },
  mounted() {
    this.GET_USER_ONBOARDING().then(() => {
      this.loaded = true

      this.USER_ONBOARDING.forEach(item => {
        let processDataItem = this.processData.filter(i => i.id === item.id)

        this.processData.forEach(processDataItem => {
          if (processDataItem.id == item.id) {
            processDataItem.done = item.done
          }
        })

        if (processDataItem !== undefined) {
          processDataItem.done = item.done
        }
      })
    })
  },
  computed: {
    ...mapGetters(['USER_ONBOARDING', 'USER_ONBOARDING_IN_PERCENT'])
  },

  methods: {
    ...mapActions(['GET_USER_ONBOARDING']),
    update() {
      this.$store.dispatch('GET_USER_ONBOARDING')
    },
    getProcessItem(id) {
        return this.processData.filter(i => i.id === id)[0]
    },
    getUncompletedProcesses: function () {
      return this.USER_ONBOARDING.filter(i => i.done === false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  .onboarding-item {
    &.bg-light {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>

