export default [
  {
    header: 'Overview',
  },
  {
    title: 'Video-Nuggets',
    icon: 'VideoIcon',
    route: 'apps-videosnippets-list',
  },
  {
    title: 'Time management',
    icon: 'ClockIcon',
    children: [
      {
        title: 'Booking Times',
        route: 'apps-bookingtimes-list',
      },
      {
        title: 'Blocked Times',
        route: 'blockedtimes',
      },
      {
        title: 'Google calendar',
        route: 'timemanagement-googlecalendar',
      },
    ]
  },
  {
    title: 'Events',
    icon: 'GlobeIcon',
    route: 'apps-events-list',
  },
  {
    title: 'Bookings',
    icon: 'BookmarkIcon',
    children: [
      {
        title: 'Upcoming',
        route: 'apps-bookings-futurebookings-list',
      },

      {
        title: 'Past',
        route: 'apps-bookings-pastbookings-list',
      },
      {
        title: 'Cancelled',
        route: 'apps-bookings-cancelledbookings-list',
      },
      {
        title: 'Requests',
        route: 'apps-bookings-requestbookings-list',
      }
    ]
  },
  {
    title: 'My Calendar',
    icon: 'CalendarIcon',
    route: 'calendar-view',
  },
  {
    title: 'Credit Management',
    icon: 'DollarSignIcon',
    route: 'credits-management',
  },
  {
    title: 'Payout methods',
    icon: 'DollarSignIcon',
    route: 'payment-method-list',
  },
  {
    header: 'Help',
  },
  {
    title: 'FAQ',
    icon: 'CreditCardIcon',
    route: 'faq',
  },
]
